<template>
  <section class="m-welfare m-scroll" ref="scroll">
    <!-- 头部导航 -->
    <HeaderBar title="Daily Deal">
      <router-link to="/en/cart" slot="right" class="solt-right">
        <img src="@/assets/index/icon_shop_b@3x.png"  alt="cart" class="right-img">
      </router-link>
    </HeaderBar>

    <div class="daily">Daily Deals</div>

    <div class="welfare-item" v-for="(item, index) in welfareList" :key="index"
    @click="$router.push({path: '/en/sublist', query: {id: item.id, title: item.title_en, num: item.row_pro_num, cid: $route.query.id}})">
      <img :src="item.pic_url" alt="">
    </div>
  </section>
</template>

<script>
import HeaderBar from '@/components/en/m-header.vue'

import { getWelfareList } from '@/api/en/mooby.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{ HeaderBar },
  data(){
    return {
      welfareList: []
    }
  },
  methods:{
    getWelfareListHandle() {
      getWelfareList(this.$route.query.id).then(res => {
        if(res) {
          this.welfareList = res.data.filter(item => {
            return item.status == 1
          })
        }
      })
    }
  },
  created(){
    this.getWelfareListHandle()
  }
}
</script>

<style lang="less" scoped>
.right-img {
  width:22px;
  height:22px;
  margin:6px;
}
@import './welfare.less';
</style>